<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">BoxStop</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <div>
          <b-overlay :show="showVerifyOverlay" rounded="sm" variant="dark">
            <div>
              <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                <b-card-title class="mb-1">
                  Account Verification!
                </b-card-title>
                <b-card-text class="mb-2">
                  Enter the verification code sent to your phone/email.
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form
                    class="auth-forgot-password-form mt-2"
                    @submit.prevent="validationForm"
                  >
                    <b-form-group label="OTP" label-for="forgot-password-email">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required"
                      >
                        <b-form-input
                          id="forgot-password-email"
                          v-model="otp"
                          :state="errors.length > 0 ? false : null"
                          name="forgot-password-email"
                          placeholder="123321"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button variant="secondary" block @click="sendOtp"
                      >Resend OTP</b-button
                    >

                    <b-button type="submit" variant="primary" block>
                      Verify Account
                    </b-button>
                  </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                  <b-link :to="{ name: 'login' }">
                    <feather-icon icon="ChevronLeftIcon" /> Back to login
                  </b-link>
                </p>
              </b-col>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import { apiUrl } from "@/constants/config";
import axios from "axios";
import { mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  data() {
    return {
      otp: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      showVerifyOverlay: false,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapMutations(["setUser"]),
    validationForm() {
      this.showVerifyOverlay = true;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios({
            url: apiUrl + "verify",
            data: { otp: this.otp },
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + this.$route.params.data.token,
            },
          })
            .then((response) => {
              //console.log(response.data.message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success: " + response.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              const item = {
                token: this.$route.params.data.token,
                user: this.$route.params.data.user,
              };

              //login user
              localStorage.setItem("user", JSON.stringify(item));
              this.setUser(item);
              setTimeout(() => {
                this.$router.replace({ path: "/login" });
              }, 500);
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Error: " +
                    (error.response.status == 401
                      ? "Unauthenticated"
                      : error.response.status == 404
                      ? "Invalid " + (this.usePhone ? "Phone" : "Email")
                      : error.response.status == 422
                      ? error.response.data.message
                      : error.message),
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.showVerifyOverlay = false;
            });
        } else {
          this.showVerifyOverlay = false;
        }
      });
    },
    sendOtp() {
      this.showVerifyOverlay = true;
      /* this.$refs.simpleRules.validate().then((success) => {
        if (success) { */
          axios({
            url: apiUrl + "send-otp",
            data: {
              email: this.$route.params.data.user.email,
              phone: this.$route.params.data.user.phone,
            },
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + this.$route.params.data.token,
            },
          })
            .then((response) => {
              //console.log(response.data.message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success: " + response.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Error: " +
                    (error.response.status == 401
                      ? "Unauthenticated"
                      : error.response.status == 404
                      ? "Invalid " + (this.usePhone ? "Phone" : "Email")
                      : error.response.status == 422
                      ? error.response.data.message
                      : error.message),
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
              if (error.response.status == 401)
                this.$router.replace({ path: "/forgot-password" });
            })
            .finally(() => {
              this.showVerifyOverlay = false;
            });
        /* } else {
          this.showVerifyOverlay = false;
        }
      }); */
    },
  },
  created() {
    if (!this.$route.params.hasOwnProperty("data"))
      this.$router.replace({ path: "/login" });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
